import * as React from 'react';
import styled from 'styled-components';
import { down, between } from 'styled-breakpoints';
import { ContainerFluid } from '../shared/Containers';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

const StyledSection = styled.section`
  z-index: 5;
`;

const Wrapper = styled.div`
  padding: 25px 0 35px;
  background-color: ${(props) => props.theme.global.color.orange};

  ${down('lgmax')} {
    padding-bottom: 300px;
  }
`;

const TextContainer = styled.div`
  max-width: 850px;
  margin: 0 auto;
  text-align: center;
  color: ${(props) => props.theme.global.color.white};

  h1 {
    margin: 0;
    text-transform: uppercase;
  }
  p {
    font-weight: 700;
    padding-bottom: 1rem;

    ${down('smmax')} {
      & > br {
        display: none;
      }
    }
  }
`;

const Content = styled.div`
  padding: 25px 0;

  ${down('lgmax')} {
    padding: 25px 30px;
  }

  ${down('xsmax')} {
    padding: 25px 1rem;
  }
`;

const ImageHolder = styled.div`
  position: absolute;
  top: 50%;
  right: -50px;
  /* left: -50%; */
  transform: translate(0, -50%);
  opacity: 0.25;
  width: 325px;

  ${down('xlmax')} {
    width: 250px;
  }

  ${down('lgmax')} {
    margin-top: 50px;
    left: 50%;
    right: unset;
    transform: translateX(-50%);
  }

  @media (max-width: 430px) {
    margin-top: 100px;
  }

  ${down('xsmax')} {
    margin-top: 0;
    margin-top: 15%;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const DownloadButton = styled.a`
  background-color: #95c11f;
  padding: 20px 25px;
  border-radius: 15px;
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
  display: inline-flex;
`;

const Kids = () => {
  const data = useStaticQuery(graphql`
    query image {
      file(relativePath: { eq: "images/homepage/heart_white.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      download: file(extension: { eq: "pdf" }) {
        publicURL
      }
    }
  `);

  return (
    <StyledSection id={'pro-deti'}>
      <ContainerFluid>
        <ImageHolder>
          <ImageWrapper>
            <Image fluid={data.file.childImageSharp.fluid} fadeIn={false} />
          </ImageWrapper>
        </ImageHolder>
        <Wrapper>
          <TextContainer>
            <Content>
              <h1>
                Poučné informace
                <br /> pro děti i celou rodinu
              </h1>
              <p>
                Psa nebo kočičku by chtělo nejspíš každé dítě.
                <br /> Zkus zjistit, jestli bude perfektní páníček. Tak s chutí
                do toho.
              </p>
              <DownloadButton
                download={'Nepořizuj_si_psa_PDF_pro_rodiny'}
                href={data.download.publicURL}
              >
                Stáhni si užitečné informace
              </DownloadButton>
            </Content>
          </TextContainer>
        </Wrapper>
      </ContainerFluid>
    </StyledSection>
  );
};

export default Kids;
