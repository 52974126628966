import * as React from 'react';
import styled from 'styled-components';
import { down, between } from 'styled-breakpoints';
import { Container } from '../shared/Containers';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

const StyledSection = styled.section`
  padding-top: 50px;
`;

const InformationWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0;

  &:nth-last-of-type(1) {
    padding-bottom: 0;
    margin-bottom: 50px;
  }
`;

const InformationBox = styled.div`
  position: relative;
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${down('mdmax')} {
    flex: 0 0 100%;
    max-width: 100%;
  }

  &:nth-of-type(1) {
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: -1.5px;
      width: 3px;
      height: 100%;
      background-color: ${(props) => props.theme.global.color.brown};

      ${down('mdmax')} {
        top: 100%;
        bottom: 0;
        height: 2px;
        width: 100%;
        left: 0;
        right: unset;
      }
    }
  }
`;

const InformationBoxCat = styled(InformationBox)`
  &:nth-of-type(1) {
    &:after {
      background-color: ${(props) => props.theme.global.color.blue};
    }
  }
`;

const BoxInner = styled.div`
  margin-right: 100px;

  p {
    font-weight: 700;
    color: ${(props) => props.theme.global.color.grey};
  }

  ${between('xl', 'xxlmax')} {
    margin-right: 75px;
  }

  ${between('lg', 'xlmax')} {
    margin-right: 50px;
  }

  ${between('md', 'lgmax')} {
    margin-right: 35px;
  }

  ${down('mdmax')} {
    margin-right: 0;
  }
`;

const ListWrapper = styled.div`
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h4 {
    margin-top: 0;
    color: ${(props) => props.theme.global.color.grey};
    font-weight: 700;
  }

  ${between('xl', 'xxlmax')} {
    margin-left: 75px;
  }

  ${between('lg', 'xlmax')} {
    margin-left: 50px;
  }

  ${between('md', 'lgmax')} {
    margin-left: 35px;
  }

  ${down('mdmax')} {
    margin-left: 0;

    h4 {
      margin-top: 1em;
    }
  }
`;

const List = styled.ul`
  color: ${(props) => props.theme.global.color.grey};
  padding-left: 22px;
`;

const BoxInnerHeader = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  h1 {
    margin-left: 2rem;
    text-transform: uppercase;
    font-size: 4rem;
  }

  ${down('lgmax')} {
    h1 {
      margin-left: 1.5rem;
      font-size: 3.25rem;
    }
  }

  ${down('mdmax')} {
    h1 {
      font-size: 4rem;
    }
  }

  ${down('smmax')} {
    justify-content: center;
    margin-left: 0;
    text-align: center;
    flex-wrap: wrap;

    h1 {
      margin-left: 0;
    }
  }
`;

const ImageWrapper = styled.div`
  flex: 0 0 40%;
  max-width: 40%;
  width: 100%;

  ${down('smmax')} {
    flex: 0 0 100%;
    max-width: 250px;
  }
`;

const Title = styled.div`
  flex: 0 0 60%;
  max-width: 60%;
  width: 100%;

  ${down('smmax')} {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
  }
`;

const DogTitle = styled.h1`
  color: ${(props) => props.theme.global.color.brown};
`;

const CatTitle = styled.h1`
  color: ${(props) => props.theme.global.color.blue};
`;

export const imagesFragment = graphql`
  fragment imagesFragment on File {
    childImageSharp {
      fluid(maxWidth: 250) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

const Information = () => {
  const data = useStaticQuery(graphql`
    query {
      dog: file(relativePath: { eq: "images/homepage/dog_icon.jpg" }) {
        ...imagesFragment
      }
      cat: file(relativePath: { eq: "images/homepage/cat_icon.jpg" }) {
        ...imagesFragment
      }
    }
  `);

  return (
    <StyledSection id={'informace'}>
      <Container>
        <InformationWrapper>
          <InformationBox>
            <BoxInner>
              <BoxInnerHeader>
                <ImageWrapper>
                  <Image
                    fluid={data.dog.childImageSharp.fluid}
                    fadeIn={false}
                  />
                </ImageWrapper>
                <Title>
                  <DogTitle>psi</DogTitle>
                </Title>
              </BoxInnerHeader>
              <p>
                Pes je parťák a je na tobě naprosto závislý. Ty máš možná
                spoustu kamarádů, ale on má jen tebe. Myslíš, že jsi ready
                přijmout tolik zodpovědnosti?
              </p>
            </BoxInner>
          </InformationBox>
          <InformationBox>
            <ListWrapper>
              <h4>Přemýšlel(a) jsi o tom, že:</h4>
              <List>
                <li>Péče o psa zabere hodně času a pozornosti?</li>
                <li>
                  Pes stojí i hodně peněz, když zvážíš všechny náklady, nejen
                  krmivo?
                </li>
                <li>
                  Některá plemena vyžadují speciální zacházení a opravdu hodně
                  zkušeností?
                </li>
                <li>Cestování se psem není tak snadné, jak si možná myslíš?</li>
              </List>
            </ListWrapper>
          </InformationBox>
        </InformationWrapper>
        <InformationWrapper>
          <InformationBoxCat>
            <BoxInner>
              <BoxInnerHeader>
                <ImageWrapper>
                  <Image
                    fluid={data.cat.childImageSharp.fluid}
                    fadeIn={false}
                  />
                </ImageWrapper>
                <Title>
                  <CatTitle>Kočky</CatTitle>
                </Title>
              </BoxInnerHeader>
              <p>
                Kočka je osobnost. Je paní domácnosti, která si tě postupně
                formuje k obrazu svému. Myslíš, že jsi ready přijmout tento
                fakt?
              </p>
            </BoxInner>
          </InformationBoxCat>
          <InformationBox>
            <ListWrapper>
              <h4>Musíš zvážit několik důležitých věcí:</h4>
              <List>
                <li>
                  I když to tak nevypadá, i kočka potřebuje lidskou pozornost.
                </li>
                <li>
                  Chov kočky se může prodražit, když zvážíš všechny náklady.
                </li>
                <li>Kočky nemají rády změny prostředí, takže ani cestování.</li>
              </List>
            </ListWrapper>
          </InformationBox>
        </InformationWrapper>
      </Container>
    </StyledSection>
  );
};

export default Information;
