import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Image from 'gatsby-image'
import styled, { keyframes } from 'styled-components'
import { down, between } from 'styled-breakpoints'
import Layout from '../components/_global/Layout'
import SEO from '../components/_global/SEO'
import Hero from '../components/homepage/Hero'
import Information from '../components/homepage/BasicInformation'
import Quiz from '../components/homepage/Quiz'
import Support from '../components/homepage/Support'
import Become from '../components/homepage/Become'
import Help from '../components/homepage/Help'
import Kids from '../components/homepage/Kids'
import About from '../components/homepage/About'

import CoverMobile from '../static/videos/cover_mobile.mp4'
import CoverTablet from '../static/videos/cover_tablet.mp4'
import CoverDesktop from '../static/videos/cover_desktop.mp4'

const HeroBanner = styled.section`
  position: relative;
  padding-top: 56.25%;
  background-color: #000;

  ${between('lg', 'xlmax')} {
    padding-top: 75%;
  }

  ${between('md', 'lgmax')} {
    padding-top: 84.375%;
  }

  ${down('mdmax')} {
    padding-top: 0;
    height: calc(100vh - 100px);
  }
`

const HeroBannerWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;

  ${down('mdmax')} {
    justify-content: center;
    flex-direction: column;
  }
`

const HeroBannerContent = styled.div`
  margin: 0 100px 125px;
  z-index: 15;

  ${down('xlmax')} {
    margin-bottom: 100px;
  }

  ${down('lgmax')} {
    margin-left: 25px;
  }

  ${down('mdmax')} {
    margin: 0;
    padding: 0 1rem;
  }
`

const HeroBannerImageWrapper = styled.div`
  max-width: 450px;

  ${down('mdmax')} {
    max-width: 75%;
    margin: 0 auto;
  }
`

const HeroBannerText = styled.p`
  color: ${(props) => props.theme.global.color.white};
  font-weight: 600;
  font-size: 2.25rem;
  text-transform: uppercase;
  margin-bottom: 25px;
  margin-top: 25px;

  ${down('lgmax')} {
    font-size: 2rem;
  }

  ${down('mdmax')} {
    text-align: center;
  }

  ${down('smmax')} {
    font-size: 1.5rem;
    max-width: 400px;
    margin: 25px auto 25px;

    & > br {
      display: none;
    }
  }
`

const HeroButtons = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

const HeroBannerButton = styled.a`
  display: inline-flex;
  padding: 20px 35px;
  border-radius: 15px;
  background-color: transparent;
  border: 3px solid ${(props) => props.theme.global.color.white};
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 700;

  ${down('mdmax')} {
    display: none;
  }
`

const HeroBannerVideo = styled.div`
  position: absolute;
  inset: 0;

  video {
    object-fit: cover;
    object-position: 50% 50%;
    width: auto;
    height: 100%;

    // width: 100%;

    // ${down('mdmax')} {
    //   object-fit: cover;
    //   object-position: 50% 50%;
    //   width: auto;
    //   height: 100%;
    // }
  }
`

const CovidBoxAnimation = keyframes`
  from{
    transform: translateX(100%);
  }
  to{
    transform: translateX(0);
  }
`

const HeroBannerCovid = styled.a`
  position: absolute;
  top: calc(50% - 125px);
  right: 0;
  background-color: #7cb227;
  z-index: 15;
  padding: 15px 25px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  text-align: center;
  transform: translateX(100%);
  animation: ${CovidBoxAnimation} 0.25s ease-in forwards;

  span {
    color: ${(props) => props.theme.global.color.white};
    font-weight: 700;
    font-size: 1.25rem;
    text-decoration: underline;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${down('xlmax')} {
    top: calc(50% - 100px);
  }

  ${down('mdmax')} {
    position: relative;
    top: 0;
    transform: none;
    animation: none;
    border-radius: 15px;
  }
`

const HeroBannerCovidImageWrapper = styled.div`
  max-width: 95px;
  margin: 0 auto 15px;
`

const HeroBannerCovidText = styled.p`
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
  color: ${(props) => props.theme.global.color.white};
`

const CovidSection = styled.section`
  position: relative;
  padding-top: 100px;
  padding-bottom: 80px;
  background-color: #7cb227;
`

const CovidContainer = styled.div`
  max-width: 675px;
  margin: 0 auto;
  text-align: center;

  ${down('lgmax')} {
    padding-left: 30px;
    padding-right: 30px;
  }

  ${down('xsmax')} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

const CovidTitle = styled.h1`
  margin: 0;
  text-transform: uppercase;
  color: ${(props) => props.theme.global.color.white};
`

const CovidText = styled.p`
  margin-top: 1rem;
  font-weight: 700;
  color: ${(props) => props.theme.global.color.white};
`

const CovidButton = styled(Link)`
  text-transform: uppercase;
  background-color: ${(props) => props.theme.global.color.white};
  color: #7cb227;
  display: inline-flex;
  margin-top: 35px;
  padding: 20px 25px;
  border-radius: 15px;
  font-size: 1.125rem;
  font-weight: 700;
`

const CovidImageWrapper = styled.div`
  max-width: 350px;
  width: 100%;
  position: absolute;
  top: 50%;
  left: -110px;
  transform: translateY(-50%);

  ${down('lgmax')} {
    position: relative;
    margin: 0 auto;
    transform: none;
    top: 0;
    left: 0;
    margin-top: 75px;
  }
`

const Root = ({ data }) => {
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const [videoSource, setVideoSource] = React.useState<string | undefined>('')

  const renderVideo = (
    source: string,
    ref: React.RefObject<HTMLVideoElement>
  ) => {
    setVideoSource(source)
    ref.current?.load()
  }

  React.useEffect(() => {
    if (typeof window !== undefined) {
      if (window.innerWidth <= 576) {
        renderVideo(CoverMobile, videoRef)
      } else if (window.innerWidth > 576 && window.innerWidth <= 992) {
        renderVideo(CoverTablet, videoRef)
      } else {
        renderVideo(CoverDesktop, videoRef)
      }
    }
  }, [])

  return (
    <Layout>
      <SEO title={'Perfektní páníček'} lang='cse' />
      <HeroBanner>
        <HeroBannerWrapper>
          <HeroBannerContent>
            <HeroBannerImageWrapper>
              <Image fluid={data.hero.childImageSharp.fluid} fadeIn={false} />
            </HeroBannerImageWrapper>
            <HeroBannerText>
              Si nepořídí čtyřnohého
              <br /> kamaráda, dokud není
              <br /> připraven se o něj postarat.
            </HeroBannerText>
            <HeroButtons>
              <div>
                <HeroBannerButton href={'#informace'}>
                  Jak se na mazlíčka připravit?
                </HeroBannerButton>
              </div>
              <div>
                <HeroBannerButton href={'#prvni-rok-pece'}>
                  Jak o mazlíčka pečovat?
                </HeroBannerButton>
              </div>
            </HeroButtons>
          </HeroBannerContent>
          <HeroBannerCovid href={'#mazlicci-a-covid'}>
            <HeroBannerCovidImageWrapper>
              <Image
                fluid={data.mask_small.childImageSharp.fluid}
                fadeIn={false}
              />
            </HeroBannerCovidImageWrapper>
            <HeroBannerCovidText>
              Jak s mazlíčkem zvládat
              <br /> změnu režimů
            </HeroBannerCovidText>
            <span>
              Poradíme ti!
              <svg
                version='1.1'
                xmlns='http://www.w3.org/2000/svg'
                width={20}
                height={20}
                fill={'#FFF'}
                style={{ marginLeft: 10 }}
                viewBox='0 0 512 512'
              >
                <g>
                  <g>
                    <path
                      d='M512,256C512,114.8,397.2,0,256,0S0,114.8,0,256s114.9,256,256,256S512,397.2,512,256z M39.7,256
			c0-119.3,97-216.3,216.3-216.3S472.3,136.7,472.3,256c0,119.3-97,216.3-216.3,216.3S39.7,375.3,39.7,256z'
                    />
                  </g>
                </g>
                <g>
                  <g>
                    <path
                      d='M228.6,369.2l99.1-99.1c7.7-7.7,7.7-20.2,0-28L228.6,143c-7.4-6.4-18.4-6.4-25.8,0c-8.3,7.1-9.3,19.7-2.2,28l85.1,85.1
			l-85.1,85.3c-7.7,7.7-7.7,20.2,0,28C208.4,376.9,220.9,376.9,228.6,369.2z'
                    />
                  </g>
                </g>
              </svg>
            </span>
          </HeroBannerCovid>
          <HeroBannerVideo>
            <video muted autoPlay playsInline loop ref={videoRef}>
              <source type='video/mp4' src={videoSource} />
            </video>
          </HeroBannerVideo>
        </HeroBannerWrapper>
      </HeroBanner>
      <Information />
      <Quiz />
      <Support />
      <Become />
      <Help />
      <CovidSection id={'mazlicci-a-covid'}>
        <CovidContainer>
          <CovidTitle>Mazlíčci v době covidu</CovidTitle>
          <CovidText>
            Jsme sice optimisté, ale nepodceňujeme rčení „štěstí přeje
            připraveným“. A tak jsme připravili návod, jak zvládat změny režimu
            se svým parťákem v případě lockdownu i rozvolňování.
          </CovidText>
          <CovidButton to={'/mazlicci-a-covid'}>CHCI VĚDĚT VÍC</CovidButton>
          <CovidImageWrapper>
            <Image fluid={data.mask_big.childImageSharp.fluid} />
          </CovidImageWrapper>
        </CovidContainer>
      </CovidSection>
      <Kids />
      <About />
    </Layout>
  )
}

export const query = graphql`
  query {
    hero: file(relativePath: { eq: "images/homepage/hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluid_tracedSVG
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mask_small: file(relativePath: { eq: "images/homepage/mask_small.png" }) {
      childImageSharp {
        fluid(maxWidth: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluid_tracedSVG
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    mask_big: file(relativePath: { eq: "images/homepage/mask_big.png" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluid_tracedSVG
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default Root
