import * as React from 'react';
import styled from 'styled-components';
import { down, between } from 'styled-breakpoints';
import { Container } from '../shared/Containers';
import { Button } from '../shared/Button';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

const StyledSection = styled.section`
  z-index: 5;
`;

const TitleWrapper = styled.div`
  padding-top: 50px;
  text-align: center;
  color: ${(props) => props.theme.global.color.grey};

  * {
    margin: 0;
  }

  h3 {
    margin-top: 1.5rem;
    margin-bottom: 25px;
  }
`;

const SupportList = styled.ul`
  list-style-position: inside;
  padding: 0 1rem;
  margin-bottom: 50px;
`;

const Activities = styled.div`
  width: 100%;
  border: 2px solid ${(props) => props.theme.global.color.blue};
  border-radius: 25px;
  margin-bottom: 50px;

  ${down('mdmax')} {
    border: none;
  }
`;

const ActivitiesInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const ActivitiesItem = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 75px;
  padding-bottom: 50px;
  margin: 0 100px;
  border-bottom: 2px solid ${(props) => props.theme.global.color.blue};

  &:nth-last-of-type(1) {
    border-bottom: none;
  }

  ${down('mdmax')} {
    margin: 0;
    border-bottom: none;

    &:nth-of-type(n + 1) {
      padding-top: 50px;
      padding-bottom: 0;
    }

    &:nth-of-type(1) {
      padding-top: 0;
    }
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  max-width: 100%;
  color: ${(props) => props.theme.global.color.grey};

  ${down('mdmax')} {
    flex-wrap: wrap;
  }

  h5 {
    color: #707070;
    margin-bottom: 0.5rem;
  }

  p {
    margin-top: 0;
  }

  ${between('sm', 'mdmax')} {
    p {
      max-width: 576px;
      margin: 0 auto;
    }
  }

  ${down('xsmax')} {
    padding: 0;
  }
`;

const ContentImage = styled.div`
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  width: 100%;

  ${down('mdmax')} {
    order: 1;
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const ContentText = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;

  ${down('mdmax')} {
    order: 2;
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const StyledHref = styled.a`
  max-width: 85px;
  margin: 25px auto 0;
  display: block;
`;

const StyledHrefExtend = styled(StyledHref)`
  max-width: 250px;
  padding: 12px 25px;
`;

const BackgroundHolderTop = styled.div`
  position: absolute;
  left: -4%;
  top: 5%;

  @media (max-width: 1550px) {
    display: none;
  }
`;

const BackgroundHolderBottom = styled.div`
  position: absolute;
  right: -4%;
  bottom: calc(5% - 50px);

  @media (max-width: 1550px) {
    display: none;
  }
`;

const BackgroundImageWrapper = styled.div`
  position: relative;
`;

export const supportLogosFragment = graphql`
  fragment supportLogosFragment on File {
    childImageSharp {
      fixed(width: 150) {
        ...GatsbyImageSharpFixed
        ...GatsbyImageSharpFixed_withWebp
      }
      fluid(maxWidth: 250) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

const Support = () => {
  const data = useStaticQuery(graphql`
    query {
      love: file(relativePath: { eq: "images/homepage/love.png" }) {
        ...supportLogosFragment
      }
      aura: file(relativePath: { eq: "images/homepage/aura.jpg" }) {
        ...supportLogosFragment
      }
      click: file(relativePath: { eq: "images/homepage/click.png" }) {
        ...supportLogosFragment
      }
      helppes: file(relativePath: { eq: "images/homepage/helppes.png" }) {
        ...supportLogosFragment
      }
      kynelog: file(relativePath: { eq: "images/homepage/kynelog.png" }) {
        ...supportLogosFragment
      }
      download: file(extension: { eq: "pdf" }) {
        publicURL
      }
      file(relativePath: { eq: "images/homepage/background.png" }) {
        childImageSharp {
          fixed(width: 175) {
            ...GatsbyImageSharpFixed
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  return (
    <StyledSection id={'podporujeme'}>
      <Container>
        <BackgroundHolderTop>
          <BackgroundImageWrapper>
            <Image fixed={data.file.childImageSharp.fixed} fadeIn={false} />
          </BackgroundImageWrapper>
        </BackgroundHolderTop>
        <TitleWrapper>
          <h2>NAŠE AKTIVITY </h2>
          <h3>Podporujeme: </h3>
          <SupportList>
            <li>zvířata v útulcích, která ve svém životě neměla moc štěstí,</li>
            <li>organizace, které díky schopnostem zvířat pomáhají lidem,</li>
            <li> kynologickou komunitu.</li>
          </SupportList>
        </TitleWrapper>
        <Activities>
          <ActivitiesInner>
            <ActivitiesItem>
              <Content>
                <ContentImage>
                  <Image
                    style={{ margin: '0 auto' }}
                    fixed={data.love.childImageSharp.fixed}
                    fadeIn={false}
                  />
                </ContentImage>
                <ContentText>
                  <h5>Mít tak pejska</h5>
                  <p>
                    Program pro mladší děti na základních školách. Dozvědí se,
                    co obnáší starat se o čtyřnohého kamaráda. Věříme, že s
                    edukací dětí je třeba začít už v mladém věku. Projekt jsme
                    vymysleli a financujeme jej, protože kromě pomoci zvířatům v
                    útulcích chceme zamezit tomu, aby v nich končila další
                    zvířata.
                  </p>
                  <StyledHrefExtend
                    as={Button}
                    href={data.download.publicURL}
                    download={'Nepořizuj_si_psa_PDF_pro_rodiny'}
                  >
                    Stáhni podklady pro děti
                  </StyledHrefExtend>
                </ContentText>
              </Content>
            </ActivitiesItem>
            <ActivitiesItem>
              <Content>
                <ContentText>
                  <h5>Aura Canis</h5>
                  <p>
                    Celkem 8 % obratu z našich online prodejů směřuje na podporu
                    terapeutických spolků, které využívají kontakt se psem jako
                    léčebnou metodu.
                  </p>
                  <StyledHref
                    as={Button}
                    href="https://www.auracanis.cz/"
                    target="_blank"
                  >
                    Zjistit víc
                  </StyledHref>
                </ContentText>
                <ContentImage>
                  <Image
                    style={{ margin: '0 auto' }}
                    fixed={data.aura.childImageSharp.fixed}
                    fadeIn={false}
                  />
                </ContentImage>
              </Content>
            </ActivitiesItem>
            <ActivitiesItem>
              <Content>
                <ContentImage>
                  <Image
                    style={{ margin: '0 auto' }}
                    fixed={data.helppes.childImageSharp.fixed}
                    fadeIn={false}
                  />
                </ContentImage>
                <ContentText>
                  <h5>Helppes</h5>
                  <p>
                    Dlouhodobě podporujeme výcvik asistenčních psů pro
                    hendikepované a taky lidi postižené ztrátou zraku.
                  </p>
                  <StyledHref
                    as={Button}
                    href="https://helppes.cz/"
                    target="_blank"
                  >
                    Zjistit víc
                  </StyledHref>
                </ContentText>
              </Content>
            </ActivitiesItem>
            <ActivitiesItem>
              <Content>
                <ContentText>
                  <h5>Click and feed</h5>
                  <p>
                    Zvířata v útulcích potřebují jíst každý den, a proto může
                    každý jedním klikem na webu naplnit jednu misku za den. Od
                    startu iniciativy jsme už naplnili téměř 10 milionů misek.
                  </p>
                  <StyledHref
                    as={Button}
                    href="https://www.clickandfeed.cz/"
                    target="_blank"
                  >
                    Zjistit víc
                  </StyledHref>
                </ContentText>
                <ContentImage>
                  <Image
                    style={{ margin: '0 auto' }}
                    fixed={data.click.childImageSharp.fixed}
                    fadeIn={false}
                  />
                </ContentImage>
              </Content>
            </ActivitiesItem>
            <ActivitiesItem>
              <Content>
                <ContentImage>
                  <Image
                    style={{ margin: '0 auto' }}
                    fixed={data.kynelog.childImageSharp.fixed}
                    fadeIn={false}
                  />
                </ContentImage>
                <ContentText>
                  <h5>Kynolog roku</h5>
                  <p>
                    Od roku 2015 organizujeme anketu Kynolog roku a necháváme
                    tak vyniknout ty nejúspěšnější z řad kynologů.
                  </p>
                  <StyledHref
                    as={Button}
                    href="https://kynologroku.cz/"
                    target="_blank"
                  >
                    Zjistit víc
                  </StyledHref>
                </ContentText>
              </Content>
            </ActivitiesItem>
          </ActivitiesInner>
        </Activities>
        <BackgroundHolderBottom>
          <BackgroundImageWrapper>
            <Image fixed={data.file.childImageSharp.fixed} fadeIn={false} />
          </BackgroundImageWrapper>
        </BackgroundHolderBottom>
      </Container>
    </StyledSection>
  );
};

export default Support;
