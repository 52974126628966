import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { down, between, up } from 'styled-breakpoints'
import { Container, ContainerFluid } from '../shared/Containers'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

const Slide = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.global.color.brown};

  ${down('smmax')} {
    padding-top: 250px;
  }
`

const SlideCat = styled(Slide)`
  background-color: ${(props) => props.theme.global.color.blue};
`

const SlideYearDog = styled(Slide)`
  background-color: #7cb227;
`

const SlideYearCat = styled(Slide)`
  background-color: #fdc300;
`

const SlideContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0;
`

const SlideContent = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  color: ${(props) => props.theme.global.color.white};
  display: flex;
  justify-content: center;
  align-items: center;

  ${down('lgmax')} {
    flex: 0 0 100%;
    max-width: 100%;
  }

  h4 {
    margin-top: 0;
    text-align: center;

    ${down('lgmax')} {
      margin-top: 50px;
    }

    ${down('xsmax')} {
      br {
        display: none;
      }
    }
  }
`

const SlideContentInner = styled.div`
  margin-right: 100px;

  ${between('xl', 'xxlmax')} {
    margin-right: 75px;
  }

  ${between('sm', 'xlmax')} {
    margin-right: 50px;
  }

  ${down('lgmax')} {
    margin-right: 0;
  }

  h2 {
    margin-top: 0;

    ${between('md', 'lgmax')} {
      text-align: center;

      & > br:nth-of-type(1) {
        display: none;
      }
    }
  }
`

const SlideList = styled.ul`
  padding-left: 23px;

  & > li {
    &:nth-of-type(1) {
      margin-left: -23px;
      list-style-type: none;
    }
  }

  ${between('md', 'lgmax')} {
    text-align: center;
    list-style-position: inside;
    padding: 0;
    max-width: 75%;
    margin: 0 auto;
  }
`

const LinksColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${(props) => props.theme.global.color.brown};

  & > h4 {
    color: ${(props) => props.theme.global.color.white};
  }
`

const LinksColumnCat = styled(LinksColumn)`
  color: ${(props) => props.theme.global.color.blue};
`

const LinkItem = styled.a`
  flex: 0 0 100%;
  max-width: 100%;
  border: 2px solid ${(props) => props.theme.global.color.white};
  color: ${(props) => props.theme.global.color.white};
  border-radius: 15px;
  margin-top: 25px;
  text-align: center;
  padding: 25px 15px;
  font-weight: 600;
  font-size: 1.25rem;
  text-transform: uppercase;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  &:hover {
    background-color: ${(props) => props.theme.global.color.white};
    color: ${(props) => props.theme.global.color.brown};
  }
`

const LinkItemCat = styled(LinkItem)`
  &:hover {
    color: ${(props) => props.theme.global.color.blue};
  }
`

const ImageHolder = styled.div`
  position: absolute;
  top: 3%;
  left: -125px;
  width: 350px;

  ${down('xlmax')} {
    width: 250px;
  }

  ${between('md', 'lgmax')} {
    width: 275px;
    top: 5%;
  }

  ${down('smmax')} {
    left: -50px;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  opacity: 0.25;
`

const ImageHolderPlus = styled.div`
  position: absolute;
  top: 3%;
  left: 0;
  width: 175px;

  ${between('md', 'lgmax')} {
    width: 125px;
    top: 5%;
  }
`

const ImageWrapperPlus = styled.div`
  position: relative;
`

const SlideContentYearButton = styled.div`
  margin-top: 50px;
`

const SlideContentYearImage = styled.div`
  max-width: 500px;
  width: 100%;
  margin-bottom: -50px;
`

const LinkYear = styled(Link)`
  flex: 0 0 100%;
  max-width: 100%;
  border: 2px solid ${(props) => props.theme.global.color.white};
  color: ${(props) => props.theme.global.color.white};
  border-radius: 15px;
  margin-top: 25px;
  text-align: center;
  padding: 10px 15px;
  font-weight: 600;
  font-size: 1.25rem;
  text-transform: uppercase;
  position: relative;
  z-index: 5;

  &:nth-of-type(1) {
    margin-top: 0;
  }

  &:hover {
    background-color: ${(props) => props.theme.global.color.white};
    color: #7cb227;
  }
`

const LinkYearCat = styled(LinkYear)`
  &:hover {
    color: #fdc300;
  }
`

export const becomeFragment = graphql`
  fragment becomeFragment on File {
    childImageSharp {
      fluid(maxWidth: 350) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

const Become = () => {
  const data = useStaticQuery(graphql`
    query {
      dog: file(relativePath: { eq: "images/homepage/dog_white.png" }) {
        ...becomeFragment
      }
      cat: file(relativePath: { eq: "images/homepage/cat_white.png" }) {
        ...becomeFragment
      }
      plus: file(relativePath: { eq: "images/homepage/plus_white.png" }) {
        ...becomeFragment
      }
      dogYear: file(relativePath: { eq: "images/homepage/dog_year.png" }) {
        ...becomeFragment
      }
      catYear: file(relativePath: { eq: "images/homepage/cat_year.png" }) {
        ...becomeFragment
      }
    }
  `)

  return (
    <>
      <section id={'jak-byt-dobrym-panickem'}>
        <Slide>
          <Container>
            <ImageHolder>
              <ImageWrapper>
                <Image fluid={data.dog.childImageSharp.fluid} fadeIn={false} />
              </ImageWrapper>
            </ImageHolder>
            <SlideContainer>
              <SlideContent>
                <SlideContentInner>
                  <h2>
                    JAK SE STÁT
                    <br /> PERFEKTNÍM
                    <br /> PÁNÍČKEM?
                  </h2>
                  <SlideList>
                    <li>Začni tím, že si odpovíš na otázky:</li>
                    <li>Budu mít na psa dost času?</li>
                    <li>Mohu si péči o psa dovolit?</li>
                    <li>Jsem připraven věnovat se jeho výchově a výcviku?</li>
                    <li>
                      Jsem k chovu psa zdravotně způsobilý (netrpím například
                      alergiemi)?
                    </li>
                    <li>Vím, jak správně vybrat vhodné plemeno?</li>
                  </SlideList>
                </SlideContentInner>
              </SlideContent>
              <SlideContent>
                <LinksColumn>
                  <h4>
                    Informace nejlépe získáš od odborníků.
                    <br /> Ideálně někde v svém okolí.
                  </h4>

                  <LinkItem
                    href={'https://krmivo-brit.cz/cs/breed-catalog'}
                    target={'_blank'}
                  >
                    Podívej se na katalog plemen psů
                  </LinkItem>
                  <LinkItem
                    href={'https://www.cmku.cz/cz/seznam-plemen-159'}
                    target={'_blank'}
                  >
                    NAJDI INFORMACE O VYBRANÉM PLEMENI
                  </LinkItem>
                  <LinkItem
                    href={'https://www.clickandfeed.cz/utulky/'}
                    target={'_blank'}
                  >
                    Najdi útulek
                  </LinkItem>
                </LinksColumn>
              </SlideContent>
            </SlideContainer>
          </Container>
        </Slide>
        <SlideCat>
          <ContainerFluid>
            <Container>
              <ImageHolder>
                <ImageWrapper>
                  <Image
                    fluid={data.cat.childImageSharp.fluid}
                    fadeIn={false}
                  />
                </ImageWrapper>
              </ImageHolder>
              <SlideContainer>
                <SlideContent>
                  <SlideContentInner>
                    <h2>
                      JAK SE STÁT
                      <br /> ZODPOVĚDNÝM
                      <br /> CHOVATELEM?
                    </h2>
                    <SlideList>
                      <li>
                        Než se rozhodneš pořídit si kočku, odpověz si na otázky:
                      </li>
                      <li>
                        Navzdory obecným představám i kočka potřebuje čas a
                        pozornost. Budu na ni mít čas?
                      </li>
                      <li>Mohu si péči o kočku dovolit?</li>
                      <li>
                        Nezpůsobí mi přítomnost kočky zdravotní komplikace
                        (netrpím například alergiemi)?
                      </li>
                      <li>Vím, jak správně vybrat vhodné plemeno?</li>
                      <li>
                        Vím, jak přizpůsobit svůj byt, aby se tam kočka cítila
                        dobře?
                      </li>
                    </SlideList>
                  </SlideContentInner>
                </SlideContent>
                <SlideContent>
                  <LinksColumnCat>
                    <h4>
                      Vše potřebné se dozvíš od odborníků,
                      <br /> ideálně někde ve svém okolí.
                    </h4>

                    <LinkItemCat
                      href={'https://krmivo-brit.cz/cs/katalog-plemen/kocky'}
                      target={'_blank'}
                    >
                      Podívej se na katalog plemen koček
                    </LinkItemCat>
                  </LinksColumnCat>
                </SlideContent>
              </SlideContainer>
            </Container>
          </ContainerFluid>
        </SlideCat>
      </section>
      <section id={'prvni-rok-pece'}>
        <SlideYearDog>
          <ContainerFluid>
            <Container>
              <ImageHolderPlus>
                <ImageWrapperPlus>
                  <Image
                    fluid={data.plus.childImageSharp.fluid}
                    fadeIn={false}
                  />
                </ImageWrapperPlus>
              </ImageHolderPlus>
              <SlideContainer>
                <SlideContent>
                  <SlideContentInner>
                    <h2>
                      MANUÁL NOVÉHO
                      <br /> PEJSKAŘE
                    </h2>
                    <p>
                      Chceš se dozvědět, jak se správně starat o svého
                      čtyřnohého kamaráda, aby z něj vyrostl zdravý a dobře
                      vychovaný pes? Provedeme tě základními chovatelskými
                      radami a tipy, o kterých možná ještě nevíš.
                    </p>
                    <SlideContentYearButton>
                      <LinkYear to={'/prvni-rok-pes'}>CHCI vědět víc</LinkYear>
                    </SlideContentYearButton>
                  </SlideContentInner>
                </SlideContent>
                <SlideContent>
                  <SlideContentYearImage>
                    <Image
                      fluid={data.dogYear.childImageSharp.fluid}
                      fadeIn={false}
                    />
                  </SlideContentYearImage>
                </SlideContent>
              </SlideContainer>
            </Container>
          </ContainerFluid>
        </SlideYearDog>
        <SlideYearCat>
          <ContainerFluid>
            <Container>
              <ImageHolderPlus>
                <ImageWrapperPlus>
                  <Image
                    fluid={data.plus.childImageSharp.fluid}
                    fadeIn={false}
                  />
                </ImageWrapperPlus>
              </ImageHolderPlus>
              <SlideContainer>
                <SlideContent>
                  <SlideContentInner>
                    <h2>
                      PRŮVODCE PÉČE
                      <br /> O KOČKU
                    </h2>
                    <p>
                      Chceš vědět, jak být své kočce dobrým parťákem? Pak jsi na
                      správném místě. Provedeme tě základy prvních měsíců vašeho
                      společného života.
                    </p>
                    <SlideContentYearButton>
                      <LinkYearCat to={'/prvni-rok-kocka'}>
                        CHCI vědět víc
                      </LinkYearCat>
                    </SlideContentYearButton>
                  </SlideContentInner>
                </SlideContent>
                <SlideContent>
                  <SlideContentYearImage>
                    <Image
                      fluid={data.catYear.childImageSharp.fluid}
                      fadeIn={false}
                    />
                  </SlideContentYearImage>
                </SlideContent>
              </SlideContainer>
            </Container>
          </ContainerFluid>
        </SlideYearCat>
      </section>
    </>
  )
}

export default Become
