import * as React from 'react';
import styled from 'styled-components';
import { between, down, up } from 'styled-breakpoints';
import { Container } from '../shared/Containers';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

const Content = styled.div`
  max-width: 750px;
  margin: 0 auto;
  padding: 50px 0;
  text-align: center;

  ${down('smmax')} {
    max-width: 100%;
  }

  h1 {
    margin: 0;
    text-transform: uppercase;
  }

  p {
    font-weight: 700;
  }

  h1,
  p,
  ul {
    color: #757374;
  }

  ul {
    list-style-position: inside;
  }
`;

const ImageWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 50px;
  display: flex;
`;

const ImageContainer = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
`;

export const imagesFragment = graphql`
  fragment imagesFragment on File {
    childImageSharp {
      fluid(maxWidth: 250) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

const Help = () => {
  const data = useStaticQuery(graphql`
    query {
      dog: file(relativePath: { eq: "images/homepage/dog_icon.jpg" }) {
        ...imagesFragment
      }
      cat: file(relativePath: { eq: "images/homepage/cat_icon.jpg" }) {
        ...imagesFragment
      }
    }
  `);

  return (
    <section id={'nevim-si-rady'}>
      <Container>
        <Content>
          <ImageWrapper>
            <ImageContainer>
              <Image fluid={data.dog.childImageSharp.fluid} fadeIn={false} />
            </ImageContainer>
            <ImageContainer>
              <Image fluid={data.cat.childImageSharp.fluid} fadeIn={false} />
            </ImageContainer>
          </ImageWrapper>
          <h1>Nezvládáte péči o čtyřnohého kamaráda?</h1>
          <p>
            Důvody můžou být různé, od rodinných, zdravotních až po finanční
            nebo problémy s výchovou zvířete. Pamatujte, že konečné rozhodnutí
            závisí vždy jen na vás a vašem uvážení. Útulek ale nemusí být tím
            jediným řešením, zde vám přinášíme několik tipů.
          </p>
          <ul>
            <li>
              Kontaktujte původního majitele, i když si nebude moci vzít zvíře
              zpět, může vám pomoci najít mu nový domov.
            </li>
            <li>
              Dejte vědět svým blízkým na sociálních sítích. Je velká šance, ho
              ujme někdo kdo vás i zvíře zná a vy ho bude mít i dále na blízku.
            </li>
            <li>
              Kontaktujte chovatelské kluby. Většinou se specializují na určitá
              plemena a mají širokou členskou základnu
            </li>
            <li>
              Pokud je komplikací zdravotní stav zvířete, kontaktuje některé ze
              specializovaných center.
            </li>
            <li>
              Pokud si myslíte, že se vaše situace brzy zlepší, a mazlíčka si
              budete moci vzít zpět k sobě, zkuste se zeptat přátel nebo rodiny,
              případně můžete kontaktovat některé z center dočasné péče.
            </li>
          </ul>
        </Content>
      </Container>
    </section>
  );
};

export default Help;
