import * as React from 'react';
import styled from 'styled-components';
import { down, between } from 'styled-breakpoints';
import { Container } from '../shared/Containers';

const TextWrapper = styled.div`
  margin: 0 150px;
  color: ${(props) => props.theme.global.color.grey};
  padding: 50px 0;

  ${between('xl', 'xxlmax')} {
    margin: 0 125px;
  }

  ${between('lg', 'xlmax')} {
    margin: 0 75px;
  }

  ${between('md', 'lgmax')} {
    margin: 0 50px;
  }

  ${between('sm', 'mdmax')} {
    margin: 0 25px;
  }

  ${down('smmax')} {
    margin: 0;
  }

  h1 {
    margin-top: 0;
    text-transform: uppercase;
  }

  p {
    margin: 0;
  }
`;

const About = () => {
  return (
    <section id={'o-projektu'}>
      <Container>
        <TextWrapper>
          <h1>o projektu</h1>
          <p>
            Každý rok končí stovky zvířat v útulcích. A většinou jen proto, že
            si jejich majitelé nenašli čas zamyslet se nad důsledky svého
            rozhodnutí. Mazlíček může udělat tvůj život pestřejším, ale také ho
            může obrátit vzhůru nohama, když nevíš, do čeho jdeš. Naším cílem je
            motivovat veřejnost k zamyšlení a následně i zodpovědnému
            rozhodnutí.
          </p>
        </TextWrapper>
      </Container>
    </section>
  );
};

export default About;
