import * as React from 'react';
import styled from 'styled-components';
import { down, up, between } from 'styled-breakpoints';
import { Link } from 'gatsby';
import { ContainerFluid } from '../shared/Containers';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';

const QuizWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const QuizBox = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;

  ${down('mdmax')} {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;

const QuizInner = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.global.color.white};
  background-color: ${(props) => props.theme.global.color.brown};
`;

const QuizInnerBlue = styled(QuizInner)`
  background-color: ${(props) => props.theme.global.color.blue};
`;

const StyledLink = styled(Link)`
  text-transform: uppercase;
  font-size: 1.125rem;
  font-weight: 700;
  border: 3px solid ${(props) => props.theme.global.color.white};
  border-radius: 15px;
  padding: 12px 50px;
  margin: 0 auto 2rem;
  max-width: 175px;
`;

const QuizContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;

  ${down('smmax')} {
    max-width: calc(100% - 60px);
    margin: 0 auto;
  }

  h2 {
    padding-top: 2.5rem;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
    font-weight: 700;

    ${down('xsmax')} {
      & > br {
        display: none;
      }
    }
  }

  img {
    width: 100%;
  }
`;

const ImageWrapper = styled.div<{ color?: string; marginTop?: string }>`
  background-color: ${(props) =>
    props.color === 'b'
      ? props.theme.global.color.blue
      : props.theme.global.color.brown};
  width: 100%;
  pointer-events: none;

  ${up('sm')} {
    margin-top: -75px;
  }

  ${between('md', 'lgmax')} {
    margin-top: -50px;
  }

  ${up('lg')} {
    margin-top: -85px;
  }
`;

export const quizImagesFragment = graphql`
  fragment quizImagesFragment on File {
    childImageSharp {
      fluid(maxWidth: 1300) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`;

const Quiz = () => {
  const data = useStaticQuery(graphql`
    query {
      dog: file(relativePath: { eq: "images/homepage/dog.png" }) {
        ...quizImagesFragment
      }
      cat: file(relativePath: { eq: "images/homepage/cat.png" }) {
        ...quizImagesFragment
      }
    }
  `);

  return (
    <section id={'kviz'}>
      <ContainerFluid>
        <QuizWrapper>
          <QuizBox>
            <QuizInner>
              <QuizContent>
                <h2>Haf!</h2>
                <p>
                  Myslíš si, že jsi připraven
                  <br /> stát se mým parťákem?
                  <br /> Udělej si kvíz, ať víme, jak jsi na tom!
                </p>
                <StyledLink to={'/psi-kviz'}>Udělat kvíz</StyledLink>
              </QuizContent>
            </QuizInner>
            <ImageWrapper>
              <Image fluid={data.dog.childImageSharp.fluid} />
            </ImageWrapper>
          </QuizBox>
          <QuizBox>
            <QuizInnerBlue>
              <QuizContent>
                <h2>Mňau!</h2>
                <p>
                  Vítej u kočičího pohovoru.
                  <br /> Přesvědčíme se, jestli jsi hoden
                  <br /> stát se mým člověkem.
                </p>
                <StyledLink to={'/kocici-kviz'}>Udělat kvíz</StyledLink>
              </QuizContent>
            </QuizInnerBlue>
            <ImageWrapper color={'b'}>
              <Image fluid={data.cat.childImageSharp.fluid} />
            </ImageWrapper>
          </QuizBox>
        </QuizWrapper>
      </ContainerFluid>
    </section>
  );
};

export default Quiz;
